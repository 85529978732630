import dynamic from 'next/dynamic'
import bannerConfigAggregator from './banner/config-aggregator'
import genericFoursixtyConfigAggregator from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/generic-foursixty/config-aggregator'
import BannerMenuShimmer from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-menu/banner-menu-shimmer'
import BannerStickyShimmer from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-sticky/banner-sticky-shimmer'
import BannerEmotionalShimmer from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-emotional/banner-emotional-shimmer'
import BannerCategoryShimmer from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-category/banner-category-shimmer'
import GenericFoursixtyShimmer from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/generic-foursixty/generic-foursixty-shimmer'

const BannerMenu = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-menu/banner-menu'
		),
	{ loading: BannerMenuShimmer }
)

const BannerSticky = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-sticky/banner-sticky'
		),
	{ loading: BannerStickyShimmer }
)

const BannerEmotional = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-emotional/banner-emotional'
		),
	{ loading: BannerEmotionalShimmer }
)

const BannerCategory = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-category/banner-category'
		),
	{ loading: BannerCategoryShimmer }
)

const GenericFoursixty = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/generic-foursixty/generic-foursixty'
		),
	{ loading: GenericFoursixtyShimmer }
)

const bannerMenuConfig = {
	configAggregator: bannerConfigAggregator,
	component: BannerMenu,
}

const bannerStickyConfig = {
	configAggregator: bannerConfigAggregator,
	component: BannerSticky,
}

const bannerEmotionalConfig = {
	configAggregator: bannerConfigAggregator,
	component: BannerEmotional,
}

const bannerCategoryConfig = {
	configAggregator: bannerConfigAggregator,
	component: BannerCategory,
}

const genericFoursixtyConfig = {
	configAggregator: genericFoursixtyConfigAggregator,
	component: GenericFoursixty,
}

const customContentTypes = {
	'banner-menu': bannerMenuConfig,
	'slide-menu': bannerMenuConfig,
	'banner-sticky': bannerStickyConfig,
	'slide-sticky': bannerStickyConfig,
	'banner-emotional': bannerEmotionalConfig,
	'slide-emotional': bannerEmotionalConfig,
	'generic-foursixty': genericFoursixtyConfig,
	'banner-category': bannerCategoryConfig,
}

export default customContentTypes
